import { micromark } from 'micromark'
import { gfmTable, gfmTableHtml } from 'micromark-extension-gfm-table'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('markdown', {
    mounted(el, binding) {
      if (binding.value) {
        const content = micromark(binding.value, {
          extensions: [gfmTable()],
          htmlExtensions: [gfmTableHtml()]
        })
        el.innerHTML = content
      }
    }
  })
})
