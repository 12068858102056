import revive_payload_client_7rGxopiAJY from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_change-case@3.1.0_nmjfutogcoatcdlf3xzoex2qii/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ElFZaDQ4N4 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_change-case@3.1.0_nmjfutogcoatcdlf3xzoex2qii/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jButCDslKc from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_change-case@3.1.0_nmjfutogcoatcdlf3xzoex2qii/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_s8bwCTnBDQ from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_change-case@3.1.0_nmjfutogcoatcdlf3xzoex2qii/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_T65J0MI7rS from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.16.4_typescript@5.4.5_vue@3.4.24_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_vpBmnicFqJ from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_change-case@3.1.0_nmjfutogcoatcdlf3xzoex2qii/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_shNUrEP0rx from "/opt/render/project/src/node_modules/.pnpm/@sentry+nuxt@8.28.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._hvhxrmma7d77qveuo3eo6agjwy/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/render/project/src/.nuxt/sentry-client-config.mjs";
import i18n_MgNfNPuQ9m from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_rollup@4.16.4_vue@3.4.24_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_bFFdR1CjXz from "/opt/render/project/src/node_modules/.pnpm/@hexdigital+nuxt-segment@1.3.5_encoding@0.1.13_rollup@4.16.4/node_modules/@hexdigital/nuxt-segment/dist/runtime/plugin.client.mjs";
import motion_otsJ4jwhlu from "/opt/render/project/src/node_modules/.pnpm/@vueuse+motion@2.1.0_rollup@4.16.4_vue@3.4.24_typescript@5.4.5_/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/render/project/src/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_thR4YAG73g from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.4_change-case@3.1.0_nmjfutogcoatcdlf3xzoex2qii/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auto_animate_uTpJuTGsMC from "/opt/render/project/src/plugins/auto-animate.ts";
import dollars_client_LRBK5r0fQn from "/opt/render/project/src/plugins/dollars.client.ts";
import event_bus_S6Iq1iGe5d from "/opt/render/project/src/plugins/event-bus.ts";
import micromark_client_KcbvnxJfxV from "/opt/render/project/src/plugins/micromark.client.ts";
import ofetch_TmnSnS1Swe from "/opt/render/project/src/plugins/ofetch.ts";
import stytch_client_mnNUxqaOyf from "/opt/render/project/src/plugins/stytch.client.ts";
export default [
  revive_payload_client_7rGxopiAJY,
  unhead_ElFZaDQ4N4,
  router_jButCDslKc,
  payload_client_s8bwCTnBDQ,
  plugin_vue3_T65J0MI7rS,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vpBmnicFqJ,
  sentry_client_shNUrEP0rx,
  sentry_client_config_o34nk2sJbg,
  i18n_MgNfNPuQ9m,
  plugin_client_bFFdR1CjXz,
  motion_otsJ4jwhlu,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_thR4YAG73g,
  auto_animate_uTpJuTGsMC,
  dollars_client_LRBK5r0fQn,
  event_bus_S6Iq1iGe5d,
  micromark_client_KcbvnxJfxV,
  ofetch_TmnSnS1Swe,
  stytch_client_mnNUxqaOyf
]